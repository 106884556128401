import React, { Component } from "react";
import DataTreeFetcher from "../../../models/dataTreeFetcher";
import ProductionDetails from "../../bricks/ProductionDetails";
import StagingDetails from "../../bricks/StagingDetails";

import "./style.scss";

class TechServer extends Component {
  constructor(props) {
    super(props);
    this.state = DataTreeFetcher.defaultValue(this.dataTree());
  }

  componentDidMount() {
    DataTreeFetcher.dataTree = this.dataTree();
    DataTreeFetcher.subscribe((x) => this.setState(x));
  }

  dataTree() {
    return {
      production: {
        path: "https://www.cheerz.com/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      konnektor_production: {
        path: "https://www.konnektor.io/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      kustom_production: {
        path: "https://kustom.cheerz.com/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      kund_production: {
        path: "https://www.kund.fr/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      fotom_production: {
        path: "https://fotomk8s.cheerz.com/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      photobooth_production: {
        path: "https://photobooth.cheerz.com/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      staging1: {
        path: "https://staging1.cheerz.com/full_status.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      staging2: {
        path: "https://staging2.cheerz.com/full_status.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      staging3: {
        path: "https://staging3.cheerz.com/full_status.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      staging4: {
        path: "https://staging4.cheerz.com/full_status.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      staging5: {
        path: "https://staging5.cheerz.com/full_status.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      staging6: {
        path: "https://staging6.cheerz.com/full_status.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      staging7: {
        path: "https://staging7.cheerz.com/full_status.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      staging8: {
        path: "https://staging8.cheerz.com/full_status.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      konnektor_staging1: {
        path: "https://staging1.konnektor.io/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      konnektor_staging2: {
        path: "https://staging2.konnektor.io/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      konnektor_staging5: {
        path: "https://staging5.konnektor.io/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      kustom_staging1: {
        path: "https://kustom-staging.cheerz.com/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      kustom_staging2: {
        path: "https://kustom-staging2.cheerz.com/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      kund_staging: {
        path: "https://kund-staging.cheerz.com/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      fotom_staging: {
        path: "https://fotom-staging.cheerz.com/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
      photobooth_staging: {
        path: "https://photobooth-staging.cheerz.com/branch.json",
        public: true,
        refresh: 2,
        initial: {},
      },
    };
  }

  render() {
    return (
      <div className="zkrin-container">
        <div className="legend-container">
          <div className="legend-item">
            <div>
              <span role="img" aria-label="ok">
                ✅
              </span>{" "}
              Up to date with production deploy
            </div>
            <div>
              <span role="img" aria-label="outdated-1">
                ❗️
              </span>{" "}
              Outdated {"<"} 1 day
            </div>
            <div>
              <span role="img" aria-label="outdated-2">
                ❌
              </span>{" "}
              Outdated between 1 and 7 days
            </div>
            <div>
              <span role="img" aria-label="outdated-3">
                💀
              </span>{" "}
              Outdated {">"} 7 days
            </div>
          </div>
          <div className="legend-item">
            <div>Staging odd together (cheerz - kustom - konnektor)</div>
            <div>Staging even together</div>
          </div>
        </div>
        <div className="flex-grid">
          <ProductionDetails name="Cheerz Production" {...this.state.production} />
          <ProductionDetails name="konnektor production" {...this.state.konnektor_production} />
          <ProductionDetails name="kund production" {...this.state.kund_production} />
          <ProductionDetails name="kustom production" {...this.state.kustom_production} />
          <ProductionDetails name="fotom production" {...this.state.fotom_production} />
          <ProductionDetails name="photobooth production" {...this.state.photobooth_production} />
        </div>
        <div className="flex-grid">
          <StagingDetails
            name="Cheerz Staging 1"
            {...this.state.staging1}
            prod={this.state.production}
            repo_url="https://github.com/cheerz/cheerz/pulls?q=head:"
          />
          <StagingDetails
            name="Cheerz Staging 2"
            {...this.state.staging2}
            prod={this.state.production}
            repo_url="https://github.com/cheerz/cheerz/pulls?q=head:"
          />
          <StagingDetails
            name="Cheerz Staging 3"
            {...this.state.staging3}
            prod={this.state.production}
            repo_url="https://github.com/cheerz/cheerz/pulls?q=head:"
          />
          <StagingDetails
            name="Cheerz Staging 4"
            {...this.state.staging4}
            prod={this.state.production}
            repo_url="https://github.com/cheerz/cheerz/pulls?q=head:"
          />
        </div>
        <div className="flex-grid">
          <StagingDetails
            name="Cheerz Staging 5"
            {...this.state.staging5}
            prod={this.state.production}
            repo_url="https://github.com/cheerz/cheerz/pulls?q=head:"
          />
          <StagingDetails
            name="Cheerz Staging 6"
            {...this.state.staging6}
            prod={this.state.production}
            repo_url="https://github.com/cheerz/cheerz/pulls?q=head:"
          />
          <StagingDetails
            name="Cheerz Staging 7"
            {...this.state.staging7}
            prod={this.state.production}
            repo_url="https://github.com/cheerz/cheerz/pulls?q=head:"
          />
          <StagingDetails
            name="Cheerz Staging 8"
            {...this.state.staging8}
            prod={this.state.production}
            repo_url="https://github.com/cheerz/cheerz/pulls?q=head:"
          />
        </div>
        <div className="flex-grid">
          <StagingDetails
            name="konnektor staging1"
            {...this.state.konnektor_staging1}
            prod={this.state.konnektor_production}
            repo_url="https://github.com/cheerz/konnektor/pulls?q=head:"
          />
          <StagingDetails
            name="konnektor staging2"
            {...this.state.konnektor_staging2}
            prod={this.state.konnektor_production}
            repo_url="https://github.com/cheerz/konnektor/pulls?q=head:"
          />
          <StagingDetails
            name="konnektor staging5 Do not use"
            {...this.state.konnektor_staging5}
            prod={this.state.konnektor_production}
            repo_url="https://github.com/cheerz/konnektor/pulls?q=head:"
          />
        </div>
        <div className="flex-grid">
          <StagingDetails
            name="kustom staging1"
            {...this.state.kustom_staging1}
            prod={this.state.kustom_production}
            repo_url="https://github.com/cheerz/kustom-backend/pulls?q=head:"
          />
          <StagingDetails
            name="kustom staging2"
            {...this.state.kustom_staging2}
            prod={this.state.kustom_production}
            repo_url="https://github.com/cheerz/kustom-backend/pulls?q=head:"
          />
        </div>
        <div className="flex-grid">
          <StagingDetails
            name="kund staging"
            {...this.state.kund_staging}
            prod={this.state.kund_production}
            repo_url="https://github.com/cheerz/kund/pulls?q=head:"
          />
          <StagingDetails
            name="fotom staging"
            {...this.state.fotom_staging}
            prod={this.state.fotom_production}
            repo_url="https://github.com/cheerz/fotom/pulls?q=head:"
          />
          <StagingDetails
            name="photobooth staging"
            {...this.state.photobooth_staging}
            prod={this.state.photobooth_production}
            repo_url="https://github.com/cheerz/photobooth/pulls?q=head:"
          />
        </div>
        <a className="dash-btn" href="dump-status">
          DB Dump status
        </a>
      </div>
    );
  }
}

export default TechServer;
